<template>
    <v-container fluid>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
                <div>
                <strong>{{ snackbar.title }}</strong>
                </div>
                <div>{{ snackbar.text }}</div>
            </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
        <v-dialog
            v-model="dialogEdit"
            persistent
            max-width="800px"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="rounded-l">
                <v-card-title class="text-center mb-0 pb-0">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-5">
                                Update Layanan
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="[dialogEdit = false]"
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text class="ma-0">
                    <v-container fluid class="ma-0">
                        <v-row>
                            <v-col class="col-12" md="2">Description</v-col>
                            <v-col class="col-12" md="10">
                                <v-textarea solo class="ma-0 pa-0 border-12" v-model="description" hide-details=true counter dense></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-12" md="2">Url Link Whatsapp</v-col>
                            <v-col class="col-12" md="10">
                                <v-text-field
                                v-model="url_link_wa"
                                solo
                                dense
                                clearable
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-12" md="2">Discount Price</v-col>
                            <v-col class="col-12" md="10">
                                <v-text-field
                                v-model="discount_price"
                                solo
                                dense
                                clearable
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-12" md="2">Price</v-col>
                            <v-col class="col-12" md="10">
                                <v-text-field
                                v-model="price"
                                solo
                                dense
                                clearable
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-12" md="2">Thumbnail About US</v-col>
                            <v-col class="col-12" md="10">
                                <v-img :src="cropImg" class="rounded-l" v-if="cropImg" max-width="200"></v-img>
                                <v-btn                          
                                    color="accent"
                                    elevation="2"
                                    outlined
                                    class="mt-5"
                                    @click="changeAvatar()"
                                >
                                    Pilih Foto
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-12" sm="12" md="12">
                                <div class="d-flex">
                                    <h6 class="blue-lcd mb-1">Create Syarat</h6>
                                    <v-spacer />
                                    <a href="https://pictogrammers.com/library/mdi/" target="_blank">Clik here to search icon</a>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table
                                    fixed-header                                                       
                                >
                                    <thead>
                                        <tr style="border-top:1px solid rgb(216 212 212);">
                                            <th class="text-left" style="width:3%" >
                                                No
                                            </th>
                                            <th class="text-left"  style="width:20%">
                                                Icon
                                            </th>
                                            <th class="text-left" style="width:50%">
                                                Syarat
                                            </th>
                                            <th class="text-center"  style="width:2%"></th>                                                    
                                        </tr>
                                    </thead>  
                                    <tbody>
                                        <tr 
                                            v-for="(detail, index) in details"
                                            :key="index"
                                        >
                                            <td class="text-left">{{ index + 1 }}</td>
                                            <td class="text-left">
                                                <v-text-field
                                                v-model="detail.icon"
                                                solo
                                                dense
                                                clearable
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                ></v-text-field>
                                            </td>
                                            <td class="text-left">
                                                <v-text-field
                                                v-model="detail.name"
                                                solo
                                                dense
                                                clearable
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-btn x-small class="text-white" color="red" @click="deleteDetail(detail, index)">
                                                    <v-icon small>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <v-btn block class="p-3 py-4" color="#d8dee2" @click="submitDetail()" >
                                                    <v-icon>
                                                        mdi-plus
                                                    </v-icon>
                                                    Tambah baris satu
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-12" sm="12" md="12">
                                <div class="d-flex">
                                    <h6 class="blue-lcd mb-1">Create Fasilitas / Tahapan</h6>
                                    <v-spacer />
                                    <a href="https://pictogrammers.com/library/mdi/" target="_blank">Clik here to search icon</a>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table
                                    fixed-header                                                     
                                >
                                    <thead>
                                        <tr style="border-top:1px solid rgb(216 212 212);">
                                            <th class="text-left" style="width:3%" >
                                                No
                                            </th>
                                            <th class="text-left"  style="width:20%">
                                                Icon
                                            </th>
                                            <th class="text-left" style="width:50%">
                                                Syarat
                                            </th>
                                            <th class="text-center"  style="width:2%"></th>                                                    
                                        </tr>
                                    </thead>  
                                    <tbody>
                                        <tr 
                                            v-for="(detail, index) in facilities"
                                            :key="index"
                                        >
                                            <td class="text-left">{{ index + 1 }}</td>
                                            <td class="text-left">
                                                <v-text-field
                                                v-model="detail.icon"
                                                solo
                                                dense
                                                clearable
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                ></v-text-field>
                                            </td>
                                            <td class="text-left">
                                                <v-text-field
                                                v-model="detail.name"
                                                solo
                                                dense
                                                clearable
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-btn x-small class="text-white" color="red" @click="deleteDetailFasilitas(detail, index)">
                                                    <v-icon small>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <v-btn block class="p-3 py-4" color="#d8dee2" @click="submitDetailFasilitas()" >
                                                    <v-icon>
                                                        mdi-plus
                                                    </v-icon>
                                                    Tambah baris satu
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-btn block class="rounded-l mr-2 p-3" color="#fff" @click="dialogEdit = false">Cancel</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-btn block class="rounded-l text-white p-3" color="success" @click="submit()">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_avatar"
            max-width="500px"
            hide-overlay
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="10">
                        <h5 style="font-weight:700; color: #1e4393;">
                        Thumbnail Image About Us
                        </h5>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="fill-height">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <h6>
                                    Thumbnail Image About Us
                                </h6>

                                <div v-if="imgSrc" style="width: 100%; height:auto; border: 1px solid gray; display: inline-block;">
                                    <vue-cropper
                                        ref='cropper'
                                        :guides="true"
                                        :view-mode="2"
                                        drag-mode="crop"
                                        :min-container-width="250"
                                        :min-container-height="180"
                                        :background="true"
                                        :rotatable="true"
                                        :src="imgSrc"
                                        :img-style="{ 'width': 'auto', 'height': 'auto' }">
                                    </vue-cropper>
                                </div>

                                <div v-else>
                                    <input type="file" name="image" id="image" @change="setImage" class="form-control" accept="image/*">
                                </div>
                            </v-col>
                        </v-row> 
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="4">
                            <v-btn block class="rounded-l text-white p-4" color="#003871" @click="saveAvatar()">Simpan</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    dense
                    :headers="headers"
                    :items="itemLists"
                    class="elevation-1 mb-5"
                    :items-per-page="30"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                    }"
                    :loading="$store.state.overlay"
                    height="440"
                    fixed-header
                    :divider="true"
                    :light="true"
                    :search="searchItem" 
                >    
                    <template v-slot:top>
                        <v-toolbar flat color="white" class="mt-2 mb-2">
                            <div class="d-flex w-100">
                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3 " v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn
                                small
                                color="#fff"
                                class="py-5 mr-3 hidden-sm-and-down"
                                @click="fetchData()"
                                >
                                    <v-icon>mdi-cached</v-icon>
                                </v-btn>
                            </div>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="grey"
                                    class="text-center"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item @click="detailItemEdit(item)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Update Layanan</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>        
    </v-container>
</template>
<script>
import {service_item_api} from "@/backend-api/service_item_api"
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
    name: "Footer",
    components:{
        VueCropper
    },
    data() {
        return {
            searchItem: '',
            umkm: null,
            details: [],
            facilities: [],
            cropImg: '',
            imgSrc: '',
            dialog_avatar: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            headers: [
                { text: '', value: 'actions', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'Title', value: 'title', align: 'left', class: 'primary--text blue lighten-5', width: '200' },
                { text: 'Description', value: 'description', align: 'left', class: 'primary--text blue lighten-5', width: '200' },
                { text: 'Link WA', value: 'url_link_wa', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Price', value: 'price', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Discount Price', value: 'discount_price', align: 'center', class: 'primary--text blue lighten-5' },  
            ],
            itemLists: [],
            dialogEdit: false,
            url_link_wa: '',
            title: '',
            description: '',
            price: '',
            discount_price: ''
        }
    },
    async mounted(){
        await this.fetchData()
    },
    methods: {
        async fetchData(){
            this.$store.dispatch('setOverlay', true)  
            var respData = await service_item_api.index(`?service_id=4`, null, false, false, false)
            if (respData.status === 200) {
                this.itemLists = respData.data.data
                this.$store.dispatch('setOverlay', false)  
                
            } 
        },
        detailItemEdit(item){
            this.dialogEdit = true
            this.umkm = item
            this.details = item.service_item_syarats
            this.facilities = item.service_item_facilities
            this.cropImg = item.image ? item.image : null
            this.url_link_wa = item.url_link_wa
            this.title = item.title
            this.description = item.description
            this.price = item.price
            this.discount_price = item.discount_price
        },
        submitDetail(){
            this.details.push(
                {
                    icon: '',
                    name: '',
                }
            )
        },
        deleteDetail(detail, index){
            this.details.splice(index, 1);
        },
        submitDetailFasilitas(){
            this.facilities.push(
                {
                    icon: '',
                    name: '',
                }
            )
        },
        deleteDetailFasilitas(detail, index){
            this.facilities.splice(index, 1);
        },
        changeAvatar(){
            this.dialog_avatar = true
        },
        reset() {
            this.imgSrc = ''
            this.cropImg = ''
        },
        setImage(e) {
            this.reset()
            const file = e.target.files[0]
            if (!file.type.includes('image/')) {
                alert('Please select an image file');
                return;
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader()
                reader.onload = (event) => {
                this.imgSrc = event.target.result
                };
                reader.readAsDataURL(file)
            } else {
                alert('Sorry, FileReader API not supported')
            }
        },
        async saveAvatar(){
            this.cropImg = this.imgSrc ? this.$refs.cropper.getCroppedCanvas().toDataURL() : ''
            this.dialog_avatar = false
        },
        async submit(){
            var reqBody = {
                'id': this.umkm ? this.umkm.id : '',
                'service_id': 4,
                'url_link_wa': this.url_link_wa,
                'title' : this.title,
                'description' : this.description,
                'image' : this.cropImg ? this.cropImg : '',
                'price' : this.price,
                'discount_price' : this.discount_price,
                'syarats' : this.details,
                'facilities' : this.facilities
            }
            this.$store.dispatch('setOverlay', true)  

            var respData = await service_item_api.update('', reqBody, false, false, false)

            if (respData.status == 200) {
                this.dialogEdit = false
                this.snackbar = {
                color: "success",
                icon: "mdi-alert-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Success",
                text: 'Success update layanan',
                visible: true
                };
                await this.fetchData()
                this.$store.dispatch('setOverlay', false)  
            } else {
                this.$store.dispatch('setOverlay', false)  
            }
        }
    }
}
</script>