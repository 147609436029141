<template>
    <v-container fluid class="p-5">
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
                <div>
                <strong>{{ snackbar.title }}</strong>
                </div>
                <div>{{ snackbar.text }}</div>
            </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
        <v-row>
            <v-col class="col-12" md="3">
                <v-card
                  class="mx-auto"
                  tile
                  height="630"
                >
                    <v-navigation-drawer permanent width="100%">
                        <v-list dense>
                            <v-list-item>
                              <v-list-item-avatar>
                                  <v-img src="@/assets/default-user.png"></v-img>
                              </v-list-item-avatar>
                            </v-list-item>

                            <v-list-item link>
                              <v-list-item-content>
                                  <v-list-item-title class="text-h6">
                                    {{$store.state.user.name}}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>{{$store.state.user.username}}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list
                            dense
                            class="mb-0 pb-0"
                        >
                            <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                            >
                            <v-list-item
                                v-for="(item, i) in items"
                                :key="i"
                                @click="clikHeader(selectedItem)"
                            >
                                <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <v-list dense class="mt-0 pt-0">
                          <v-list-group
                            v-for="item in item_services"
                            :key="item.text"
                            :prepend-icon="item.icon"
                            no-action
                            v-model="item.active"
                          >
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                              </v-list-item-content>
                            </template>

                            <v-list-item
                              v-for="(child, i) in item.items"
                              :key="i"
                              @click="clickUrl(child, i)"
                              v-model="child.active"
                            >
                              <v-list-item-content>
                                <v-list-item-title v-text="child.text"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-group>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-col>
            <v-col class="col-12" md="9">
              <v-card tile v-if="selectedItem == 0" height="630">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Profile LegalPedia</h1>
                    <div class="zptdmA">Kelola informasi Profile LegalPedia Anda untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <v-container fluid>
                      <v-row>
                        <v-col class="col-12" md="2">Email</v-col>
                        <v-col class="col-12" md="10">
                          <v-text-field
                            :value="email ? email.value : ''"
                            solo
                            dense
                            clearable
                            class="ma-0 pa-0 border-12"
                            hide-details=true
                            id="email"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="col-12" md="2">Phone</v-col>
                        <v-col class="col-12" md="10">
                          <v-text-field
                            :value="phone ? phone.value : ''"
                            solo
                            dense
                            clearable
                            class="ma-0 pa-0 border-12"
                            hide-details=true
                            id="phone"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="col-12" md="2">Facebook</v-col>
                        <v-col class="col-12" md="10">
                          <v-text-field
                            :value="facebook ? facebook.value : ''"
                            solo
                            dense
                            clearable
                            class="ma-0 pa-0 border-12"
                            hide-details=true
                            id="facebook"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="col-12" md="2">Instagram</v-col>
                        <v-col class="col-12" md="10">
                          <v-text-field
                            :value="instagram ? instagram.value : ''"
                            solo
                            dense
                            clearable
                            class="ma-0 pa-0 border-12"
                            hide-details=true
                            id="instagram"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="col-12" md="2">Linkedin</v-col>
                        <v-col class="col-12" md="10">
                          <v-text-field
                            :value="linkedin ? linkedin.value : ''"
                            solo
                            dense
                            clearable
                            class="ma-0 pa-0 border-12"
                            hide-details=true
                            id="linkedin"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="col-12" md="2">Whatsapp</v-col>
                        <v-col class="col-12" md="10">
                          <v-text-field
                            :value="whatsapp ? whatsapp.value : ''"
                            solo
                            dense
                            clearable
                            class="ma-0 pa-0 border-12"
                            hide-details=true
                            id="whatsapp"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                          <v-col cols="12" xs="12" sm="3" md="3">
                              <v-btn block class="rounded-l mr-2 p-3" color="#fff" @click="[dialogSlider = false, clear()]">Cancel</v-btn>
                          </v-col>
                          <v-col cols="12" xs="12" sm="3" md="3">
                              <v-btn block class="rounded-l text-white p-3" color="success" @click="saveProfile()" >Save</v-btn>
                          </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-card-text>
              </v-card>
              <v-card tile v-if="selectedItem == 1" height="630">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Header Slider Text</h1>
                    <div class="zptdmA">Kelola informasi Header Slider Text Anda untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <v-simple-table
                      fixed-header
                      height="400px"
                      dense
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Title
                            </th>
                            <th class="text-left">
                              Subtitle
                            </th>
                            <th class="text-left">
                              
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="slide in slides"
                            :key="slide.id"
                          >
                            <td class="p-1">{{ slide.title }}</td>
                            <td class="p-1">{{ slide.subtitle }}</td>
                            <td class="p-1">
                              <v-btn x-small class="mt-2 ml-0 mr-2" color="warning" @click="editSlide(slide)"><v-icon small>mdi-pencil</v-icon></v-btn>
                              <v-btn x-small class="mt-2 ml-0" color="red" @click="deleteSlide(slide)"><v-icon small color="#fff">mdi-delete</v-icon></v-btn>
                            </td>
                          </tr>
                          <tr class="pt-3">
                            <td colspan="3" class="pt-3">
                                <v-btn block class="p-3 py-4" color="#d8dee2" @click="dialogSlider = true" >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                    Tambah baris satu
                                </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-card-text>
              </v-card>
              <v-dialog
                v-model="dialogSlider"
                persistent
                max-width="600px"
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <v-card class="rounded-l">
                  <v-card-title class="text-center mb-0 pb-0">
                      <v-row>
                          <v-col cols="12">
                              <div class="d-flex">
                                  <div class="ml-5">
                                    Header Slider Text
                                  </div>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      class="mx-2"
                                      fab
                                      x-small
                                      color="#e83e8c;"
                                      @click="[dialogSlider = false, clear()]"
                                  >
                                      <v-icon>
                                          mdi-close
                                      </v-icon>
                                  </v-btn>
                              </div>
                          </v-col>
                          <v-divider></v-divider>
                      </v-row>
                  </v-card-title>
                  <v-card-text class="ma-0">
                    <v-container fluid class="ma-0">
                      <v-row>
                        <v-col class="col-12" sm="12" md="12">
                          <h6 class="blue-lcd mb-1">Title <strong style="color:red;">*</strong><span></span></h6>
                          <v-text-field
                            v-model="title"
                            solo
                            dense
                            clearable
                            class="ma-0 pa-0 border-12"
                            hide-details=true
                          ></v-text-field>
                        </v-col>
                        <v-col class="col-12" sm="12" md="12">
                          <h6 class="blue-lcd mb-1">Subtitle <strong style="color:red;">*</strong><span></span></h6>
                          <v-textarea solo class="ma-0 pa-0 border-12" v-model="subtitle" hide-details=true counter dense></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="text-center" >
                      <v-row justify="center">
                          <v-col cols="12" xs="12" sm="3" md="3">
                              <v-btn block class="rounded-l mr-2 p-3" color="#fff" @click="[dialogSlider = false, clear()]">Cancel</v-btn>
                          </v-col>
                          <v-col cols="12" xs="12" sm="3" md="3">
                              <v-btn block class="rounded-l text-white p-3" color="success" @click="submitSlider()">Save</v-btn>
                          </v-col>
                      </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-card tile v-if="selectedItem == 2" height="630" style="overflow: auto;">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">About Us</h1>
                    <div class="zptdmA">Kelola informasi About Us Anda untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <v-container fluid>
                      <v-row>
                        <v-col class="col-12" md="2">Title</v-col>
                        <v-col class="col-12" md="10">
                          {{ about ? about.title : '' }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="col-12" md="2">Description</v-col>
                        <v-col class="col-12" md="10">
                          <v-textarea solo class="ma-0 pa-0 border-12" :value="about ? about.description : ''" hide-details=true counter dense id="description_about_us"></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="col-12" md="2">Iframe Youtube</v-col>
                        <v-col class="col-12" md="10">
                          <v-textarea solo class="ma-0 pa-0 border-12" :value="about ? about.value : ''" hide-details=true counter dense id="value_about_us"></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="col-12" md="2">Thumbnail About US</v-col>
                        <v-col class="col-12" md="10">
                          <v-img :src="cropImg" class="rounded-l" v-if="cropImg" max-width="200"></v-img>
                          <v-img src="@/assets/default.png" class="rounded-l" v-else max-width="200"></v-img>
                          <v-btn                          
                              color="accent"
                              elevation="2"
                              outlined
                              class="mt-5"
                              @click="changeAvatar()"
                          >
                              Pilih Foto
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                          <v-col cols="12" xs="12" sm="3" md="3">
                              <v-btn block class="rounded-l mr-2 p-3" color="#fff" @click="[dialogSlider = false, clear()]">Cancel</v-btn>
                          </v-col>
                          <v-col cols="12" xs="12" sm="3" md="3">
                              <v-btn block class="rounded-l text-white p-3" color="success" @click="saveAboutus()" >Save</v-btn>
                          </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-card-text>
              </v-card>
              <v-dialog
                v-model="dialog_avatar"
                max-width="500px"
                hide-overlay
              >
                <v-card class="p-3 rounded-xl">
                    <v-card-title class="text-center">
                        <v-row align="center" justify="center">
                            <v-col cols="10">
                            <h5 style="font-weight:700; color: #1e4393;">
                            Thumbnail Image About Us
                            </h5>
                            </v-col>
                        </v-row>  
                    </v-card-title>
                    <v-card-text>
                        <v-container fluid class="fill-height">
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12">
                                    <h6>
                                        Thumbnail Image About Us
                                    </h6>

                                    <div v-if="imgSrc" style="width: 100%; height:auto; border: 1px solid gray; display: inline-block;">
                                        <vue-cropper
                                            ref='cropper'
                                            :guides="true"
                                            :view-mode="2"
                                            drag-mode="crop"
                                            :min-container-width="250"
                                            :min-container-height="180"
                                            :background="true"
                                            :rotatable="true"
                                            :src="imgSrc"
                                            :img-style="{ 'width': 'auto', 'height': 'auto' }">
                                        </vue-cropper>
                                    </div>

                                    <div v-else>
                                        <input type="file" name="image" id="image" @change="setImage" class="form-control" accept="image/*">
                                    </div>
                                </v-col>
                            </v-row> 
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="text-center">
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" @click="saveAvatar()">Simpan</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
              </v-dialog>
              <v-card tile v-if="selectedItem == 3" height="630" style="overflow: auto;">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Layanan Izin UMKM</h1>
                    <div class="zptdmA">Kelola informasi Layanan Izin UMKM untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <izin-umkm></izin-umkm>
                  </div>
                </v-card-text>
              </v-card>
              <v-card tile v-if="selectedItem == 4" height="630" style="overflow: auto;">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Layanan Pendirian PT</h1>
                    <div class="zptdmA">Kelola informasi Layanan Pendirian PT untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <pendirian-p-t></pendirian-p-t>
                  </div>
                </v-card-text>
              </v-card>
              <v-card tile v-if="selectedItem == 5" height="630" style="overflow: auto;">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Layanan Pendirian PT Perorangan</h1>
                    <div class="zptdmA">Kelola informasi Layanan Pendirian PT Perorangan untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <pendirian-pt-perorangan></pendirian-pt-perorangan>
                  </div>
                </v-card-text>
              </v-card>
              <v-card tile v-if="selectedItem == 6" height="630" style="overflow: auto;">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Layanan OSS RBA</h1>
                    <div class="zptdmA">Kelola informasi Layanan OSS RBA untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <layanan-oss-rba></layanan-oss-rba>
                  </div>
                </v-card-text>
              </v-card>
              <v-card tile v-if="selectedItem == 7" height="630" style="overflow: auto;">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Layanan Pendirian CV</h1>
                    <div class="zptdmA">Kelola informasi Layanan Pendirian CV untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <pendirian-cv></pendirian-cv>
                  </div>
                </v-card-text>
              </v-card>
              <v-card tile v-if="selectedItem == 8" height="630" style="overflow: auto;">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Layanan Penutupan Perusahaan</h1>
                    <div class="zptdmA">Kelola informasi Layanan Penutupan Perusahaan untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <penutupan-perusahaan></penutupan-perusahaan>
                  </div>
                </v-card-text>
              </v-card>
              <v-card tile v-if="selectedItem == 9" height="630" style="overflow: auto;">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Layanan Pembuatan dan Perubahan Akta</h1>
                    <div class="zptdmA">Kelola informasi Layanan Pembuatan dan Perubahan Akta untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <perubahan-akta></perubahan-akta>
                  </div>
                </v-card-text>
              </v-card>
              <v-card tile v-if="selectedItem == 10" height="630" style="overflow: auto;">
                <v-card-text class="b7wtmP">
                  <div class="_66hYBa">
                    <h1 class="SbCTde">Layanan Pendaftaran Merek</h1>
                    <div class="zptdmA">Kelola informasi Layanan Pendaftaran Merek untuk memanipulasi data</div>
                  </div>
                  <div class="R-Gpdg">
                    <pendaftaran-merek></pendaftaran-merek>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {appl_constant_api} from "@/backend-api/appl_constant_api"
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import IzinUmkm from './IzinUmkm.vue'
import PendirianPT from './PendirianPT.vue'
import PendirianPtPerorangan from './PendirianPtPerorangan.vue'
import LayananOssRba from './LayananOssRba.vue'
import PendirianCv from './PendirianCv.vue'
import PenutupanPerusahaan from './PenutupanPerusahaan.vue'
import PerubahanAkta from './PerubahanAkta.vue'
import PendaftaranMerek from './PendaftaranMerek.vue'

export default {
  name: "Footer",
  components:{
    VueCropper,
    IzinUmkm,
    PendirianPT,
    PendirianPtPerorangan,
    LayananOssRba,
    PendirianCv,
    PenutupanPerusahaan,
    PerubahanAkta,
    PendaftaranMerek
  },
  data() {
        return {
            value: 1,
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            q: '',
            about: null,
            phone: null,
            phoneData: null,
            email: null,
            emailData: null,
            facebook: null,
            facebookData: null,
            instagram: null,
            instagramData: null,
            linkedin: null,
            linkedinData: null,
            slides: [],
            selectedItem: 0,
            items: [
                { text: 'Profile LegalPedia', icon: 'mdi-account-circle' },
                { text: 'Header Slider Text', icon: 'mdi-folder' },
                { text: 'About LegalPedia', icon: 'mdi-account-multiple' },
            ],
            item_services: [
              {
                icon: 'mdi-face-agent',
                active: true,
                items: [
                  { text: 'Izin UMKM', url: '/admin/izin-umkm', selectedItem: 3 , active: false},
                  { text: 'Pendirian PT', url: '/admin/pendirian-pt', selectedItem: 4  , active: false},
                  { text: 'Pendirian PT Perorangan', url: '/admin/pendirian-pt-perorangan', selectedItem: 5  , active: false},
                  { text: 'Layanan OSS RBA', url: '/admin/layanan-oss', selectedItem: 6  , active: false},
                  { text: 'Pendirian CV', url: '/admin/pendirian-cv', selectedItem: 7  , active: false},
                  { text: 'Penutupan Perusahaan', url: '/admin/penutupan-perusahaan', selectedItem: 8  , active: false},
                  { text: 'Pembuatan dan Perubahan Akta', url: '/admin/pembuatan-perubahan-akta', selectedItem: 9  , active: false},
                  { text: 'Pendaftaran Merek', url: '/admin/pendaftaran-merek', selectedItem: 10  , active: false},
                  // { text: 'Konsultasi Hukum', url: '/admin/konsultasi-hukum' },
                  // { text: 'Kontraktor dan Arsitek', url: '/admin/kontraktor' },
                ],
                text: 'Service',
              },
            ],
            dialogSlider: false,
            title: '',
            subtitle: '',
            detailSlider: null,
            statusSave: 'create',
            cropImg: '',
            imgSrc: '',
            dialog_avatar: false,
            url: '',
            whatsapp: null
        }
  },
  async mounted(){
    this.q = this.$route.query.dashboard
    this.$store.dispatch('setOverlay', true)  
    await this.getAboutUs()
    await this.getEmail()
    await this.getPhone()
    await this.getSocialMedia()
    await this.getSliderText()
    await this.getWhatsapp()
    this.$store.dispatch('setOverlay', false)  
    //console.log(this.q)
  },
  methods: {
    async getAboutUs(){
      var respData = await appl_constant_api.index(`?key_code=CONFIGABOUTUS`, null, false, false, false)
      if (respData.status === 200) {
        this.about = respData.data.data ? respData.data.data[0] : null
        this.cropImg = this.about ? (this.about.image ? this.about.image : '') : ''
      } 
    },
    async getWhatsapp(){
      var respData = await appl_constant_api.index(`?key_code=CONFIGWHATSAPP`, null, false, false, false)
      if (respData.status === 200) {
        this.whatsapp = respData.data.data ? respData.data.data[0] : null
      } 
    },
    async getEmail(){
      var respData = await appl_constant_api.index(`?key_code=CONFIGEMAIL`, null, false, false, false)
      if (respData.status === 200) {
        this.email = respData.data.data ? respData.data.data[0] : null
      } 
    },
    async getPhone(){
      var respData = await appl_constant_api.index(`?key_code=CONFIGPHONE`, null, false, false, false)
      if (respData.status === 200) {
        this.phone = respData.data.data ? respData.data.data[0] : null
      } 
    },
    async getSocialMedia(){
      var respDataFacebook = await appl_constant_api.index(`?key_code=CONFIGSOSMED&title=Facebook`, null, false, false, false)
      if (respDataFacebook.status === 200) {
        this.facebook = respDataFacebook.data.data ? respDataFacebook.data.data[0] : null
      } 
      var respDataInstagram = await appl_constant_api.index(`?key_code=CONFIGSOSMED&title=Instagram`, null, false, false, false)
      if (respDataInstagram.status === 200) {
        this.instagram = respDataInstagram.data.data ? respDataInstagram.data.data[0] : null
      } 
      var respDataLinkedin = await appl_constant_api.index(`?key_code=CONFIGSOSMED&title=Linkedin`, null, false, false, false)
      if (respDataLinkedin.status === 200) {
        this.linkedin = respDataLinkedin.data.data ? respDataLinkedin.data.data[0] : null
      } 
    },
    async getSliderText(){
      this.slides = []
      var respData = await appl_constant_api.index(`?key_code=HEADERSLIDER`, null, false, false, false)
      if (respData.status === 200) {
        for (let index = 0; index < respData.data.data.length; index++) {
          const element = respData.data.data[index];
          this.slides.push({
            id : element.id,
            title : element.title,
            subtitle : element.description,
            key_code: element.key_code,
            value: element.value
          })
        }
      } 
    },
    clear(){
      this.title = ''
      this.subtitle = ''
      this.statusSave = 'create'
    },
    editSlide(slide){
      this.statusSave = 'update'
      this.detailSlider = slide
      this.title = slide.title
      this.subtitle = slide.subtitle
      this.dialogSlider = true
    },
    async deleteSlide(slide){
      console.log(slide);
        var reqBody = {
          'key_code': 'HEADERSLIDER',
          'id': slide.id
        }

        var respData = await appl_constant_api.destroy('', reqBody, false, false, false)

        if (respData.status === 200) {
          this.clear()
          this.getSliderText()
        }
    },
    async submitSlider(){
      this.$store.dispatch('setOverlay', true)
      var reqBody = {
        'key_code': 'HEADERSLIDER',
        'title': this.title,
        'description': this.subtitle,
        'id': this.detailSlider ? this.detailSlider.id : ''
      }

      if (this.statusSave == 'create') {
        var respData = await appl_constant_api.store('', reqBody, false, false, false)
      } else {
        var respData = await appl_constant_api.update('', reqBody, false, false, false)
      }
      if (respData.status === 200) {
        this.clear()
        this.getSliderText()
        this.dialogSlider = false
        this.$store.dispatch('setOverlay', false)  

        this.snackbar = {
          color: "success",
          icon: "mdi-alert-circle",
          mode: "multi-line",
          position: "top",
          timeout: 7500,
          title: "Success",
          text: 'Successfully save Slider Text',
          visible: true
        };
      }
    },
    async saveProfile(){
      this.$store.dispatch('setOverlay', true)
      await this.saveEmail()
      await this.savePhone()
      await this.saveFacebook()
      await this.saveInstagram()
      await this.saveLinkedin()
      await this.saveWhatsapp()

      await this.getEmail()
      await this.getPhone()
      await this.getSocialMedia()
      await this.getWhatsapp()

      this.$store.dispatch('setOverlay', false)
    },
    async saveWhatsapp(){
      var reqBody = {
        'key_code': 'CONFIGWHATSAPP',
        'title': 'LINK Whatsapp Legalpedia',
        'description': 'LINK Whatsapp Legalpedia',
        'value' : $('#whatsapp').val(),
        'id': this.whatsapp ? this.whatsapp.id : ''
      }

      if (this.whatsapp) {
        var respData = await appl_constant_api.update('', reqBody, false, false, false)
      } else {
        var respData = await appl_constant_api.store('', reqBody, false, false, false)
      }

      return respData

    },
    async saveEmail(){
      var reqBody = {
        'key_code': 'CONFIGEMAIL',
        'title': 'Email Legalpedia',
        'description': 'Email Legalpedia',
        'value' : $('#email').val(),
        'id': this.email ? this.email.id : ''
      }

      if (this.email) {
        var respData = await appl_constant_api.update('', reqBody, false, false, false)
      } else {
        var respData = await appl_constant_api.store('', reqBody, false, false, false)
      }

      return respData

    },
    async savePhone(){
      var reqBody = {
        'key_code': 'CONFIGPHONE',
        'title': 'Phone Legalpedia',
        'description': 'Phone Legalpedia',
        'value' : $('#phone').val(),
        'id': this.phone ? this.phone.id : ''
      }

      if (this.phone) {
        var respData = await appl_constant_api.update('', reqBody, false, false, false)
      } else {
        var respData = await appl_constant_api.store('', reqBody, false, false, false)
      }

      return respData
    },
    async saveFacebook(){
      var reqBody = {
        'key_code': 'CONFIGSOSMED',
        'title': 'Facebook',
        'description': 'Facebook',
        'value' : $('#facebook').val(),
        'id': this.facebook ? this.facebook.id : ''
      }

      if (this.facebook) {
        var respData = await appl_constant_api.update('', reqBody, false, false, false)
      } else {
        var respData = await appl_constant_api.store('', reqBody, false, false, false)
      }

      return respData
    },
    async saveInstagram(){
      var reqBody = {
        'key_code': 'CONFIGSOSMED',
        'title': 'Instagram',
        'description': 'Instagram',
        'value' : $('#instagram').val(),
        'id': this.instagram ? this.instagram.id : ''
      }

      if (this.instagram) {
        var respData = await appl_constant_api.update('', reqBody, false, false, false)
      } else {
        var respData = await appl_constant_api.store('', reqBody, false, false, false)
      }

      return respData
    },
    async saveLinkedin(){
      var reqBody = {
        'key_code': 'CONFIGSOSMED',
        'title': 'Linkedin',
        'description': 'Linkedin',
        'value' : $('#linkedin').val(),
        'id': this.linkedin ? this.linkedin.id : ''
      }

      if (this.linkedin) {
        var respData = await appl_constant_api.update('', reqBody, false, false, false)
      } else {
        var respData = await appl_constant_api.store('', reqBody, false, false, false)
      }

      return respData
    },
    changeAvatar(){
      this.dialog_avatar = true
    },
    reset() {
      this.imgSrc = ''
      this.cropImg = ''
    },
    setImage(e) {
      this.reset()
      const file = e.target.files[0]
      if (!file.type.includes('image/')) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
        };
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    async saveAvatar(){
      this.cropImg = this.imgSrc ? this.$refs.cropper.getCroppedCanvas().toDataURL() : ''
      this.dialog_avatar = false
    },
    async saveAboutus(){
      this.$store.dispatch('setOverlay', true)  

      var reqBody = {
        'key_code': 'CONFIGABOUTUS',
        'title': 'Tentang LEGALPEDIA',
        'description': $('#description_about_us').val(),
        'value' : $('#value_about_us').val(),
        'id': this.about ? this.about.id : '',
        'image' : this.cropImg ? this.cropImg : ''
      }
      this.$store.dispatch('setOverlay', true)  

      if (this.about) {
        var respData = await appl_constant_api.update('', reqBody, false, false, false)
      } else {
        var respData = await appl_constant_api.store('', reqBody, false, false, false)
      }

      if (respData.status == 200) {
        await this.getAboutUs()
        this.$store.dispatch('setOverlay', false)  
      }


    },
    clikHeader(selectedItem){
      this.selectedItem = selectedItem

      for (let index = 0; index < this.item_services[0].items.length; index++) {
        const element = this.item_services[0].items[index];
        element.active = false
      }
    },
    clickUrl(item, index){
      this.selectedItem = item.selectedItem
      for (let index = 0; index < this.item_services[0].items.length; index++) {
        const element = this.item_services[0].items[index];
        element.active = false
      }
      this.item_services[0].items[index].active = item.active == true ? false : true
    }
  }
};
</script>