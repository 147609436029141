<template>
    <v-container fluid>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
                <div>
                <strong>{{ snackbar.title }}</strong>
                </div>
                <div>{{ snackbar.text }}</div>
            </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
        <v-row>
            <v-col class="col-12" md="2">Title</v-col>
            <v-col class="col-12" md="10">
                <v-text-field
                :value="umkm ? umkm.title : ''"
                solo
                dense
                clearable
                class="ma-0 pa-0 border-12"
                hide-details=true
                id="title"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12" md="2">Description</v-col>
            <v-col class="col-12" md="10">
                <v-textarea solo class="ma-0 pa-0 border-12" :value="umkm ? umkm.description : ''" id="description" hide-details=true counter dense></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12" md="2">Url Link Whatsapp</v-col>
            <v-col class="col-12" md="10">
                <v-text-field
                :value="umkm ? umkm.url_link_wa : ''"
                solo
                dense
                clearable
                class="ma-0 pa-0 border-12"
                hide-details=true
                id="url_link_wa"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12" md="2">Discount Price</v-col>
            <v-col class="col-12" md="10">
                <v-text-field
                :value="umkm ? umkm.discount_price : ''"
                solo
                dense
                clearable
                class="ma-0 pa-0 border-12"
                hide-details=true
                id="discount_price"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12" md="2">Price</v-col>
            <v-col class="col-12" md="10">
                <v-text-field
                :value="umkm ? umkm.price : ''"
                solo
                dense
                clearable
                class="ma-0 pa-0 border-12"
                hide-details=true
                id="price"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12" sm="12" md="12">
                <div class="d-flex">
                    <h6 class="blue-lcd mb-1">Create Syarat</h6>
                    <v-spacer />
                    <a href="https://pictogrammers.com/library/mdi/" target="_blank">Clik here to search icon</a>
                </div>
            </v-col>
            <v-col cols="12">
                <v-simple-table
                    fixed-header                                                       
                >
                    <thead>
                        <tr style="border-top:1px solid rgb(216 212 212);">
                            <th class="text-left" style="width:3%" >
                                No
                            </th>
                            <th class="text-left"  style="width:20%">
                                Icon
                            </th>
                            <th class="text-left" style="width:50%">
                                Syarat
                            </th>
                            <th class="text-center"  style="width:2%"></th>                                                    
                        </tr>
                    </thead>  
                    <tbody>
                        <tr 
                            v-for="(detail, index) in details"
                            :key="index"
                        >
                            <td class="text-left">{{ index + 1 }}</td>
                            <td class="text-left">
                                <v-text-field
                                v-model="detail.icon"
                                solo
                                dense
                                clearable
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                ></v-text-field>
                            </td>
                            <td class="text-left">
                                <v-text-field
                                v-model="detail.name"
                                solo
                                dense
                                clearable
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                ></v-text-field>
                            </td>
                            <td>
                                <v-btn x-small class="text-white" color="red" @click="deleteDetail(detail, index)">
                                    <v-icon small>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <v-btn block class="p-3 py-4" color="#d8dee2" @click="submitDetail()" >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                    Tambah baris satu
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12" sm="12" md="12">
                <div class="d-flex">
                    <h6 class="blue-lcd mb-1">Create Fasilitas / Tahapan</h6>
                    <v-spacer />
                    <a href="https://pictogrammers.com/library/mdi/" target="_blank">Clik here to search icon</a>
                </div>
            </v-col>
            <v-col cols="12">
                <v-simple-table
                    fixed-header                                                     
                >
                    <thead>
                        <tr style="border-top:1px solid rgb(216 212 212);">
                            <th class="text-left" style="width:3%" >
                                No
                            </th>
                            <th class="text-left"  style="width:20%">
                                Icon
                            </th>
                            <th class="text-left" style="width:50%">
                                Syarat
                            </th>
                            <th class="text-center"  style="width:2%"></th>                                                    
                        </tr>
                    </thead>  
                    <tbody>
                        <tr 
                            v-for="(detail, index) in facilities"
                            :key="index"
                        >
                            <td class="text-left">{{ index + 1 }}</td>
                            <td class="text-left">
                                <v-text-field
                                v-model="detail.icon"
                                solo
                                dense
                                clearable
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                ></v-text-field>
                            </td>
                            <td class="text-left">
                                <v-text-field
                                v-model="detail.name"
                                solo
                                dense
                                clearable
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                ></v-text-field>
                            </td>
                            <td>
                                <v-btn x-small class="text-white" color="red" @click="deleteDetailFasilitas(detail, index)">
                                    <v-icon small>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <v-btn block class="p-3 py-4" color="#d8dee2" @click="submitDetailFasilitas()" >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                    Tambah baris satu
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" xs="12" sm="3" md="3">
                <v-btn block class="rounded-l mr-2 p-3" color="#fff">Cancel</v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3">
                <v-btn block class="rounded-l text-white p-3" color="success" @click="submit()">Save</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {service_item_api} from "@/backend-api/service_item_api"
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
    name: "Footer",
    components:{
        VueCropper
    },
    data() {
        return {
            umkm: null,
            details: [],
            facilities: [],
            cropImg: '',
            imgSrc: '',
            dialog_avatar: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
        }
    },
    async mounted(){
        await this.fetchData()
    },
    methods: {
        async fetchData(){
            var respData = await service_item_api.index(`?service_id=1`, null, false, false, false)
            if (respData.status === 200) {
                this.umkm = respData.data.data ? respData.data.data[0] : null
                this.details = this.umkm ? this.umkm.service_item_syarats : []
                this.facilities = this.umkm ? this.umkm.service_item_facilities : []
            } 
        },
        submitDetail(){
            this.details.push(
                {
                    icon: '',
                    name: '',
                }
            )
        },
        deleteDetail(detail, index){
            this.details.splice(index, 1);
        },
        submitDetailFasilitas(){
            this.facilities.push(
                {
                    icon: '',
                    name: '',
                }
            )
        },
        deleteDetailFasilitas(detail, index){
            this.facilities.splice(index, 1);
        },
        changeAvatar(){
            this.dialog_avatar = true
        },
        reset() {
            this.imgSrc = ''
            this.cropImg = ''
        },
        setImage(e) {
            this.reset()
            const file = e.target.files[0]
            if (!file.type.includes('image/')) {
                alert('Please select an image file');
                return;
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader()
                reader.onload = (event) => {
                this.imgSrc = event.target.result
                };
                reader.readAsDataURL(file)
            } else {
                alert('Sorry, FileReader API not supported')
            }
        },
        async saveAvatar(){
            this.cropImg = this.imgSrc ? this.$refs.cropper.getCroppedCanvas().toDataURL() : ''
            this.dialog_avatar = false
        },
        async submit(){
            var reqBody = {
                'id': this.umkm ? this.umkm.id : '',
                'service_id': 1,
                'url_link_wa': $('#url_link_wa').val(),
                'title' : $('#title').val(),
                'description' : $('#description').val(),
                'image' : this.cropImg ? this.cropImg : '',
                'price' : $('#price').val(),
                'discount_price' : $('#discount_price').val(),
                'syarats' : this.details,
                'facilities' : this.facilities
            }
            this.$store.dispatch('setOverlay', true)  

            var respData = await service_item_api.update('', reqBody, false, false, false)

            if (respData.status === 200) {
                await this.fetchData()
                this.$store.dispatch('setOverlay', false)  
            }
        }
    }
}
</script>